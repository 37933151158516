'use client';

import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

import { Icons } from '../icons';

const BubbleMessages = dynamic(() =>
  import('./bubble-messages').then((mod) => mod.BubbleMessages),
);

export const WhatsAppFakeChat = () => {
  const ref = useRef(null);
  const [intersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let observerRefValue: Element | null = null;

    if (ref.current) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIntersecting(true);
        } else {
          // setIntersecting(false)
        }
      });

      intersectionObserver.observe(ref.current);
      observerRefValue = ref.current;

      return () => {
        if (observerRefValue) {
          intersectionObserver.unobserve(observerRefValue);
        }
      };
    }
  });
  return (
    <div ref={ref}>
      <div className="animate-mobileActive fixed bottom-12 right-6 z-50 md:right-8">
        {intersecting ? (
          <>
            <BubbleMessages />
            <a
              href="https://wa.me/551221028592"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Avatar className="flex size-14 cursor-pointer items-center justify-center md:size-16">
                <AvatarImage
                  src="https://randomuser.me/api/portraits/men/51.jpg"
                  alt="Profile picture of Carlos"
                />
                <AvatarFallback className="bg-[#14a800]">
                  {' '}
                  <Icons.whattsapp className="size-9 md:size-11" />
                </AvatarFallback>
              </Avatar>
            </a>
            <div className="absolute bottom-0 right-0">
              <div className="relative top-4 size-4 animate-ping rounded-full bg-[#14a800] opacity-75" />
              <div className="size-4 rounded-full bg-[#14a800]" />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
